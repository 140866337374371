<template>
  <HeaderBar title="All sites">
    <template v-if="apps.length > 0" #subtitle-actions>
      <Options :close-on-select="true">
        <template #toggle>
          <div class="flex items-center gap-1 text-sm font-medium text-black opacity-50 hover:opacity-75 transition ease-in-out cursor-pointer duration-75">
            <span>{{ sortByLabel }}</span>
            <Icon name="triangle-combo" size="10" />
          </div>
        </template>
        <template #items>
          <OptionsItem title="Last edited" @click="getApps('last-edited', 'Last Edited')" />
          <OptionsItem title="Alphabetically" @click="getApps('alphabetically', 'Alphabetically')" />
        </template>
      </Options>
    </template>

    <template v-if="canCreateApps" #actions>
      <Btn size="small" :href="user.email_verified_at === null ? '#' : appCreateRoute" :disabled="user.email_verified_at === null">
        New project
      </Btn>
    </template>
  </HeaderBar>

  <Alert v-if="!hasAvailableProjects" type="neutral" class="mb-5">
    You've reached the project limit for your current account plan.
    <a :href="accountDetailsRoute">
      Upgrade
    </a>
  </Alert>

  <Alert v-if="user.email_verified_at === null" type="warning" class="mb-5">
    <form class="inline" method="POST" action="{{ route('verification.resend') }}">
      Before starting your project please verify your email address by clicking the link sent to <span class="italic">{{ user.email }}</span>.
      <input type="hidden" name="_token" :value="csrfToken">
      <button type="submit" class="font-medium underline focus:outline-none focus:shadow-none">
        Resend verification email
      </button>
    </form>
  </Alert>

  <div
    v-if="walkthroughData.show_badge && !walkthroughBadgeWasClosed"
    class="px-6 py-3.5 rounded-lg bg-blue-100 flex items-center gap-3 mb-6 relative group"
  >
    <div class="flex flex-col text-gray-900">
      <span class="text-sm font-medium">Take a walk-through tour</span>
      <span class="text-xs opacity-75">Learn Ycode basics in less than 5 minutes.</span>
    </div>

    <div class="ml-auto">
      <Btn
        size="small"
        type="link"
        icon-name="arrow-right"
        icon-right="true"
        :href="walkthroughData.route"
      >
        Start walk-through
      </Btn>
    </div>

    <div
      class="absolute -top-2.5 -right-2.5 bg-white rounded-full opacity-0 group-hover:opacity-100 transition ease-in-out cursor-pointer"
      @click="setUserSetting('show_walkthrough_badge', false)"
    >
      <div class="bg-gray-900 rounded-full text-white flex items-center justify-center border-4 border-white p-1 opacity-50 hover:opacity-100 transition ease-in-out">
        <Icon name="close" size="10" />
      </div>
    </div>
  </div>

  <a
    v-if="apps.length === 0 && canCreateApps"
    :href="appCreateRoute"
    class="h-full border rounded-xl border-black border-opacity-10 hover:border-opacity-20 p-20 flex flex-col items-center justify-center space-y-6 {{ is_null(auth()->user()->email_verified_at) ? 'pointer-events-none' : '' }}"
    :class="{ 'pointer-events-none': (user.email_verified_at === null) }"
  >
    <Btn
      icon-name="plus"
      size="large"
      rounded-full="true"
      :class="{ 'opacity-50 pointer-events-none' : (user.email_verified_at === null) }"
    />
    <p
      class="text-black text-opacity-50 tracking-wide xl:w-1/3 text-center"
      :class="{ 'opacity-50 pointer-events-none' : (user.email_verified_at === null) }"
    >
      You don’t have any projects yet. <br>Click + to create your very first project!
    </p>
  </a>

  <template v-if="apps.length > 0">
    <div class="grid gap-6 md:gap-0 grid-cols-1 md:divide-y md:border-t md:border-b md:mb-0 mb-20">
      <AppRow
        v-for="app in appsList"
        :key="app.data.id"
        :app-master="app"
        :permissions="permissions"
        :user-permissions="userPermissions"
        :allow-new-projects="hasAvailableProjects"
        :user="user"
      />
    </div>
  </template>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    apps: {
      default: [],
    },
    user: {
      default: null,
    },
    appCreateRoute: {
      default: null,
    },
    accountDetailsRoute: {
      default: null,
    },
    hasAvailableProjects: {
      default: false,
    },
    canCreateApps: {
      default: false,
    },
    permissions: {
      default: {},
    },
    userPermissions: {
      default: {},
    },
    csrfToken: {
      default: null,
    },
    walkthroughData: {
      default: {},
    },
  },

  data() {
    return {
      sortByLabel: 'Last edited',
      appsList: _.cloneDeep(this.apps),
      walkthroughBadgeWasClosed: false,
    }
  },

  methods: {
    getApps(sortBy, sortByLabel) {
      const apps = _.cloneDeep(this.apps)

      this.sortByLabel = sortByLabel

      if (sortBy === 'alphabetically') {
        this.appsList = apps.sort((a, b) => a.data.name.localeCompare(b.data.name))
      } else {
        this.appsList = apps.sort((a, b) => b.data.last_save_at - a.data.last_save_at)
      }
    },

    setUserSetting(key, value) {
      this.walkthroughBadgeWasClosed = true
      axios.post('/user/setting', { key, value }).catch(() => {})
    },
  },
}
</script>
