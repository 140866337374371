<template>
  <div class="fixed inset-0 flex items-end pointer-events-none lg:p-8 p-6 justify-end z-50">
    <Transition
      enter-active-class="transform ease-out duration-300 transition"
      enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="showNotification" class="lg:max-w-sm w-full bg-black bg-opacity-75 shadow-lg rounded-lg pointer-events-auto" style="backdrop-filter: blur(30px); -webkit-backdrop-filter: blur(30px);">
        <div class="rounded-lg shadow-xs overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0 mt-1.5">
                <Icon v-if="type === 'success'" name="check-solid" class="text-green-300" size="18" />
                <Icon v-if="type === 'error'" name="exclamation-solid" class="text-red-300" size="18" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <span class="text-sm leading-5 font-medium tracking-wide text-white mb-0.5">
                  {{ types[type].title }}
                </span>
                <p class="text-xs tracking-wide text-white text-opacity-75 leading-snug">
                  <slot name="message" />
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <Btn
                  type="secondary-inverse"
                  icon-name="close"
                  size="xsmall"
                  rounded-full="true"
                  @click="showNotification = false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
    },
    details: {
      default: '',
    },
    type: {
      default: 'success',
    },
    show: {
      default: false,
    },
  },

  data() {
    return {
      showNotification: this.show,
      types: {
        success: { style: '', title: 'Done' },
        error: { style: '', title: 'Something went wrong' },
      },
    }
  },
}
</script>

<style scoped>

</style>
