<template>
  <div>
    <div @click="toggleModal(true)">
      <slot name="toggle" />
    </div>
    <Teleport to="body">
      <div v-if="showModal" class="fixed w-full h-full top-0 left-0 flex z-50 py-20 overflow-auto bg-black bg-opacity-50">
        <div
          class="bg-white p-10 rounded-xl relative z-50 space-y-8 m-auto overflow-scroll"
          :class="{
            'lg:w-156': fixedWidth,
            'w-full max-w-screen-md': !fixedWidth,
          }"
        >
          <div v-if="header" class="flex justify-between items-center">
            <div v-if="title || details">
              <h2 class="text-2xl">
                {{ title }}
              </h2>
              <span v-if="details" class="text-sm tracking-wide text-black text-opacity-50">{{ details }}</span>
            </div>

            <Btn
              icon-name="close"
              type="secondary"
              rounded-full="true"
              size="small"
              class="absolute top-0 right-0 m-5"
              @click="toggleModal(false)"
            />
          </div>

          <div
            v-if="$slots.body"
            class="tracking-wide text-black text-opacity-50 whitespace-normal"
          >
            <Btn
              v-if="closeFloating"
              icon-name="close"
              type="ghost"
              rounded-full="true"
              size="small"
              class="absolute top-0 right-0 m-3 z-20"
              @click="toggleModal(false)"
            />

            <slot name="body" />
          </div>

          <div v-if="showCloseBtn || $slots.footer" class="flex items-center justify-end space-x-3">
            <Btn v-if="showCloseBtn" size="small" type="secondary" @click="toggleModal(false)">
              Close
            </Btn>
            <slot name="footer" />
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
export default {

  props: {
    title: {
      default: '',
    },
    details: {
      default: '',
    },
    show: {
      default: false,
    },
    fixedWidth: {
      default: true,
    },
    showCloseBtn: {
      default: true,
    },
    header: {
      default: true,
    },
    closeFloating: {
      default: false,
    },
  },

  emits: ['update:show', 'close'],

  data() {
    return {
      showModal: this.show,
    }
  },
  watch: {
    show(val) {
      this.showModal = val
    },
  },

  methods: {
    toggleModal(state) {
      this.showModal = state
      this.$emit('update:show', state)
      if (!state) {
        this.$emit('close')
      }
    },
  },
}
</script>

<style scoped>

</style>
