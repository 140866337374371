/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vuex from 'vuex'
import Vue, { createApp } from 'vue'
import numeral from 'numeral'
import mitt from 'mitt'

const emitter = mitt()

require('./bootstrap')

const store = new Vuex.Store({
  state: {
    appTokens: null,
    radioValue: null,
  },

  getters: {
    appTokens: (state) => state.appTokens,
  },

  mutations: {
    setRadioValue(state, value) {
      state.radioValue = value
    },

    setAppToken(state, value) {
      state.appTokens = value
    },
  },
})

const app = createApp({
  data() {
    return {
      pricingTab: 'yearly',
      selectedUsageType: null,
      showAdvanceDomainInfo: true,
      showAgencyFreelancerOptions: false,
      validUsageOptions: ['a', 'b', 'c', 'd'],
    }
  },

  computed: {
    canSubmitSurvey() {
      return this.validUsageOptions.includes(this.selectedUsageType)
    },
  },

  methods: {
    track(event, data = {}) {
      axios.post('https://app.ycode.com/api/v1/events', { event, data })
    },

    setSurveyUsageAnswer(e) {
      const value = e?.target?.value

      this.selectedUsageType = value

      // this.showAgencyFreelancerOptions = true
    },

    goToFreeLancerOptions() {
      this.showAgencyFreelancerOptions = true
    },

    backSurveyUsageAnswer() {
      this.selectedUsageType = null
      this.showAgencyFreelancerOptions = false
    },
  },
}).use(store)

app.config.globalProperties.emitter = emitter

window.Vue = app // this is important! Do not use require('vue')
window.Vue.use(Vuex)

app.config.globalProperties.$filters = {
  /**
   *
   * @param value
   * @param format
   * @param currency
   * @returns {string}
   */
  numFormat(value, format = '0.00', currency = '$') {
    if (value < 0) value = 0
    return currency + numeral(value / 100).format(format)
  },

  /**
   *
   * @param brand
   * @returns {*|string}
   */
  formatCardBrand(brand) {
    if (brand === 'visa') return brand.toUpperCase()
    return brand
  },
}

app.component('Btn', require('./elements/Btn.vue').default)
app.component('TabItem', require('./elements/TabItem.vue').default)
app.component('Notification', require('./elements/Notification.vue').default)
app.component('Alert', require('./elements/Alert.vue').default)
app.component('BrandYcode', require('./elements/BrandYcode.vue').default)
app.component('Icon', require('./elements/Icon.vue').default)
app.component('HeaderBar', require('./elements/HeaderBar.vue').default)
app.component('Modal', require('./elements/Modal.vue').default)

app.component('EmptyState', require('./elements/EmptyState.vue').default)
app.component('Badge', require('./elements/Badge.vue').default)
app.component('SidebarItem', require('./elements/sidebar/SidebarItem.vue').default)
app.component('Spinner', require('./elements/Spinner.vue').default)
app.component('App', require('./elements/App.vue').default)
app.component('AppRow', require('./elements/AppRow.vue').default)
app.component('AppListing', require('./elements/AppListing.vue').default)
app.component('UserAvatar', require('./elements/UserAvatar.vue').default)

// Options

app.component('Options', require('./elements/options/Options.vue').default)
app.component('OptionsItem', require('./elements/options/OptionsItem.vue').default)
app.component('OptionsToggle', require('./elements/options/OptionsToggle.vue').default)
app.component('OptionsDivider', require('./elements/options/OptionsDivider.vue').default)

// Forms
app.component('FieldLabel', require('./elements/forms/FieldLabel.vue').default)
app.component('TextField', require('./elements/forms/TextField.vue').default)
app.component('TextArea', require('./elements/forms/TextArea.vue').default)
app.component('SelectField', require('./elements/forms/SelectField.vue').default)
app.component('Password', require('./elements/forms/Password.vue').default)
app.component('ErrorMessage', require('./elements/forms/ErrorMessage.vue').default)

// Radio groups
app.component('RadioGroup', require('./elements/radio-groups/RadioGroup.vue').default)
app.component('RadioGroupItem', require('./elements/radio-groups/RadioGroupItem.vue').default)

// Checkbox groups
app.component('CheckboxGroup', require('./elements/checkbox-groups/CheckboxGroup.vue').default)
app.component('CheckboxGroupItem', require('./elements/checkbox-groups/CheckboxGroupItem.vue').default)

// Cards
app.component('Card', require('./elements/cards/Card.vue').default)
app.component('TemplateCard', require('./elements/cards/TemplateCard.vue').default)

app.component('UserProjectSelection', require('./elements/UserProjectSelection.vue').default)

app.component('ImageSlider', require('./elements/ImageSlider.vue').default)

// app.component('Password', require('../../vendor/remotecompany/accounts/resources/js/Password.vue').default)
// app.component('Avatar', require('../../../vendor/remotecompany/accounts/resources/js/Avatar.vue').default)
// app.component('TwoFactorAuthEnableModal', require('../../../vendor/remotecompany/accounts/resources/js/TwoFactorAuthEnableModal.vue').default)
// app.component('TwoFactorAuthDisableModal', require('../../../vendor/remotecompany/accounts/resources/js/TwoFactorAuthDisableModal.vue').default)

app.component('GenerateFont', require('./elements/fonts/GenerateFont.vue').default)

app.component('InviteTeam', require('./elements/InviteTeam.vue').default)

app.component('Templates', require('./elements/Templates.vue').default)

app.component('Updates', require('./elements/Updates.vue').default)

// Add the token
app.config.globalProperties.$csrf = document.head.querySelector('meta[name="csrf-token"]').content
app.mount('#ycode-app')
