<template>
  <div class="w-full">
    <textarea
      :id="fieldId"
      ref="inputFocus"
      class="w-full border placeholder-black placeholder-opacity-50 focus:ring-0 focus:border-black text-black rounded-[10px]"
      :placeholder="placeholder"
      :class="[
        [sizing[size].input],
        disabled ? 'cursor-not-allowed border-opacity-50 bg-white text-black' : '',
        error ? 'border-red-400' : 'border-gray-400 focus:border-black',
      ]"
      :rows="rows"
      :value="modelValue || value"
      :name="name"
      :min="min"
      :required="required"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      :disabled="disabled"
      @input="debounceInput"
      @change="$emit('change', $event)"
    />
    <span
      v-if="error && errorMessage"
      class="block text-red-400 tracking-wide mt-1"
      :class="[
        [sizing[size].error],
      ]"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {},
    min: {
      type: Number,
      required: false,
    },
    placeholder: {
      default: 'Enter',
    },
    disabled: {
      default: false,
    },
    size: {
      default: 'default',
    },
    required: {
      default: false,
    },
    fieldId: {},
    value: {},
    autocomplete: {
      default: false,
    },
    error: {
      default: false,
    },
    errorMessage: {},
    name: {},
    autofocus: {
      default: false,
    },
    maxlength: {
      default: '',
    },
    debounceTime: {
      default: 500,
    },
    rows: {
      default: '4',
    },
  },
  emits: ['input', 'change', 'update:modelValue'],
  data() {
    return {
      sizing: {
        default: { input: 'py-3 px-5 text-base', error: 'text-xs' },
      },
    }
  },
  mounted() {
    if (this.autofocus) {
      const InputFocusRef = this.$refs.inputFocus
      InputFocusRef.focus()
    }
  },

  methods: {
    debounceInput(event) {
      clearTimeout(this.debounce)

      this.debounce = setTimeout(() => {
        const { value } = event.target

        this.$emit('input', value)
        this.$emit('update:modelValue', value)
      }, this.debounceTime)
    },
  },
}
</script>
