<template>
  <div class="w-full relative">
    <select
      :id="fieldId"
      :name="name"
      :value="modelValue || value"
      :required="required"
      :disabled="disabled"
      :title="title"
      class="w-full border placeholder-black placeholder-opacity-50 focus:ring-0 focus:border-black appearance-none"
      :class="[
        [sizing[size].input],
        disabled ? 'cursor-not-allowed border-opacity-50 bg-white text-black' : '',
        error ? 'border-red-600' : 'border-gray-400 focus:border-black',
      ]"
      @change="handleChange"
    >
      <option v-if="placeholder" value="" selected>
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="option.value === selected"
      >
        {{ option.name }}
      </option>
    </select>
    <div class="absolute top-3.5 w-5 h-5 right-2 bg-white pointer-events-none flex items-center">
      <Icon name="triangle-combo" class="opacity-30" size="12" />
    </div>
    <span
      v-if="error && errorMessage"
      class="block text-red-400 tracking-wide mt-1"
      :class="[
        [sizing[size].error],
      ]"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
export default {

  props: {
    options: {
      default: [],
    },
    selected: {
      default: null,
    },
    modelValue: {},
    disabled: {
      default: false,
    },
    type: {
      default: 'text',
    },
    placeholder: {},
    required: {
      default: false,
    },
    fieldId: {},
    value: {},
    error: {
      default: false,
    },
    size: {
      default: 'default',
    },
    errorMessage: {},
    name: {},
    title: {
      default: '',
    },
  },

  emits: ['select', 'update:modelValue'],

  data() {
    return {
      sizing: {
        default: { input: 'py-3 px-5 text-base rounded-[10px]', error: 'text-xs' },
      },
    }
  },

  mounted() {},

  methods: {
    handleChange(event) {
      const { value } = event.target

      this.$emit('update:modelValue', value)
      this.$emit('select', value)
    },
  },
}
</script>

<style scoped>

select:required:invalid {
  @apply text-black text-opacity-50;
}

</style>
