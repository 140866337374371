<template>
  <div class="flex items-center space-x-2">
    <Btn
      size="small"
      icon-name="arrow-left"
      :disabled="currentPage === 1"
      @click="currentPage -= 1"
    />
    <Btn
      size="small"
      icon-name="arrow-right"
      :disabled="currentPage === Math.ceil(totalList.length / perPage) || disableNext"
      @click="currentPage += 1"
    />
  </div>
</template>

<script>
export default {
  props: {
    totalList: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      default: 15,
    },
    disableNext: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:slicedList'],

  data() {
    return {
      currentPage: 1,
      slicedList: [],
    }
  },

  watch: {
    slicedList() {
      this.$emit('update:slicedList', this.slicedList)
    },

    currentPage() {
      this.slicedList = this.totalList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      )
    },
  },

  created() {
    this.slicedList = this.totalList.slice(0, this.perPage)
  },
}
</script>
