<template>
  <span
    class="inline-flex items-center px-1 py-0.5 rounded text-xs uppercase leading-3 border"
    :class="[
      [types[type].style],
    ]"
  >
    {{ title }}
  </span>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
    },
    type: {
      default: 'success',
    },
  },
  data() {
    return {
      types: {
        success: { style: 'border-green-600 text-green-600' },
        warning: { style: 'border-yellow-600 text-yellow-600' },
        neutral: { style: 'border-black border-opacity-10 text-black' },
        dark: { style: 'border-black bg-black text-white' },
      },
    }
  },
}
</script>

<style scoped>

</style>
