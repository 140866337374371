<template>
  <div class="w-full">
    <div class="flex justify-center items-center">
      <div v-if="prefix" class="border border-r-0 placeholder-black placeholder-opacity-50 focus:ring-0 focus:border-black text-black py-3 pl-5 pr-1 text-base border-gray-400 focus:border-black rounded-tl-[10px] rounded-bl-[10px]">
        <span class="text-gray-700 py-3 sm:text-sm">{{ prefix }}</span>
      </div>
      <input
        :id="fieldId"
        ref="inputFocus"
        class="w-full border placeholder-black placeholder-opacity-50 focus:ring-0 focus:border-black text-black"
        :placeholder="placeholder"
        :class="[
          [sizing[size].input],
          disabled ? 'cursor-not-allowed border-opacity-50 bg-white text-black' : '',
          error ? 'border-red-400' : 'border-gray-400 focus:border-black',
          prefix ? 'rounded-tl-none rounded-bl-none pl-1' : 'border-l-1 rounded-[10px] pl-5',
          postfix ? 'rounded-tr-none rounded-br-none pr-1' : 'border-r-1 rounded-[10px] pr-5',
          inputClass ? inputClass : '',
        ]"
        :type="type"
        :value="modelValue || value"
        :name="name"
        :min="min"
        :required="required"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        :disabled="disabled"
        @input="debounceInput"
        @change="$emit('change', $event)"
      >
      <div v-if="postfix" class="border border-l-0 placeholder-black placeholder-opacity-50 focus:ring-0 focus:border-black text-black py-3 pl-1 pr-5 text-base border-gray-400 focus:border-black rounded-tr-[10px] rounded-br-[10px]">
        <span class="text-gray-700 py-3 sm:text-sm">{{ postfix }}</span>
      </div>
    </div>
    <span
      v-if="error && errorMessage"
      class="block text-red-400 tracking-wide mt-1"
      :class="[
        [sizing[size].error],
      ]"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {},
    min: {
      type: Number,
      required: false,
    },
    placeholder: {
      default: 'Enter',
    },
    disabled: {
      default: false,
    },
    size: {
      default: 'default',
    },
    type: {
      default: 'text',
    },
    required: {
      default: false,
    },
    fieldId: {},
    value: {},
    autocomplete: {
      default: false,
    },
    error: {
      default: false,
    },
    errorMessage: {},
    name: {},
    autofocus: {
      default: false,
    },
    maxlength: {
      default: '',
    },
    prefix: {
      default: false,
    },
    postfix: {
      default: false,
    },
    debounceTime: {
      default: 500,
    },
    inputClass: {
      type: String,
    },
  },
  emits: ['input', 'change', 'update:modelValue'],
  data() {
    return {
      sizing: {
        default: { input: 'py-3 text-base', error: 'text-xs' },
        small: { input: 'py-2.5 text-sm', error: 'text-xs' },
      },
    }
  },
  mounted() {
    if (this.autofocus) {
      const InputFocusRef = this.$refs.inputFocus
      InputFocusRef.focus()
    }
  },

  methods: {
    debounceInput(event) {
      clearTimeout(this.debounce)

      this.debounce = setTimeout(() => {
        const { value } = event.target

        this.$emit('input', value)
        this.$emit('update:modelValue', value)
      }, this.debounceTime)
    },
  },
}
</script>

<style>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {

  opacity: 1;

}
</style>
