<template>
  <Component
    :is="tag"
    class="flex items-center space-x-1.5 focus:outline-none focus:shadow-none outline-none select-none tracking-wide transition duration-100 ease-in-out text-sm text-black leading-5 px-3 py-1 rounded-md font-medium"
    :href="href"
    :type="tag === 'button' && buttonType"
    :class="[
      buttonType === 'button' ? 'inline-block' : '',
      disabled ? 'cursor-not-allowed opacity-50' : '',
      isActive ? 'bg-black bg-opacity-5' : 'text-opacity-75 hover:text-opacity-100',
    ]"
  >
    <Icon
      v-if="icon"
      :name="icon"
      :class="[
        isActive ? '' : 'opacity-50',
      ]"
      size="12"
    />
    <span><slot /></span>
  </Component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      default: 'button',
    },
    icon: {
      default: null,
    },
  },
  computed: {
    tag() {
      return this.href ? 'a' : 'button'
    },
  },
}
</script>
