<template>
  <div class="group">
    <div
      class="h-56 rounded-lg mb-4 relative flex justify-center items-center overflow-hidden group bg-gray-300"
      :class="app.pending ? 'cursor-wait' : 'cursor-pointer'"
    >
      <div v-if="activeUsers && activeUsers.length" class="flex absolute bottom-0 left-0 pl-3 pb-3 space-x-1 z-20">
        <template v-for="user of activeUsers" :key="user.id">
          <UserAvatar
            :initials="user.initials"
            :bg-color="user.bg_color"
            :has-avatar="user.hasAvatar"
            :avatar-url="user.avatarUrl"
            size="tiny"
          />
        </template>
      </div>
      <a
        v-if="!app.pending"
        :href="appRedirectUrl"
        class="bg-black bg-opacity-60 w-full h-full absolute opacity-0 transition duration-150 ease-in-out flex flex-col gap-4 justify-center items-center z-40"
      />
      <div class="h-full w-full border border-black border-opacity-10 rounded-lg z-10 absolute" />
      <template v-if="app.pending">
        <div class="flex flex-col items-center space-y-1.5">
          <Icon name="spinner" class="text-black text-opacity-50" size="16" />
          <span class="text-sm opacity-30 tracking-wide">Preparing project</span>
        </div>
      </template>
      <template v-else>
        <div
          v-if="app.thumbnail"
          class="h-full w-full bg-cover transform group-hover:scale-105 transition duration-300 ease-in-out rounded-lg bg-top"
          :style="{ backgroundImage: 'url(' + app.thumbnail + ')' }"
        />
        <Icon v-else name="image-solid" class="text-black text-opacity-20" size="20" />
      </template>
    </div>

    <div class="flex justify-between">
      <div class="flex flex-col truncate">
        <h3 class="text-md truncate">
          {{ app.name }}
        </h3>

        <div class="flex items-center space-x-2">
          <a :href="appPlanUrl" class="text-black opacity-50 hover:underline text-xs tracking-wide">{{ app.plan }}</a>
          <div class="h-0.5 w-0.5 rounded-full bg-black opacity-25" />
          <span class="text-xs text-black opacity-50 tracking-wide">
            <a
              v-if="domain"
              target="_blank"
              :href="'https://' + domain"
              class="block  hover:underline"
            >{{ domain }}</a>
            <template v-else>Not published</template>
          </span>
        </div>
      </div>

      <div class="ml-4 m-0.5 opacity-0 transition duration-100 ease-in-out group-hover:opacity-100">
        <Options>
          <template #toggle>
            <Btn icon-name="more" size="xxsmall" type="secondary" />
          </template>
          <template #items>
            <OptionsItem
              v-if="hasPermission(permissions.manage_project_general_settings)"
              icon="database-solid"
              title="Content editor"
              :href="appEditorUrl"
            />
            <OptionsDivider />
            <OptionsItem
              v-if="hasPermission(permissions.manage_project_general_settings)"
              icon="cog-solid"
              title="Settings"
              :href="appSettingsUrl"
            />
            <OptionsItem
              v-if="hasPermission(permissions.manage_seo)"
              icon="search-circle"
              title="SEO"
              :href="appSeoUrl"
            />

            <OptionsItem
              v-if="hasPermission(permissions.manage_project_billing)"
              icon="card-solid"
              :href="appPlanUrl"
              :title="isFreePlan ? 'Upgrade plan' : 'Change plan'"
            />

            <OptionsDivider v-if="hasPermission(permissions.duplicate_project) || (isFreePlan && hasPermission(permissions.delete_project))" />

            <form v-if="hasPermission(permissions.duplicate_project)" ref="modalDeleteAppForm" :action="appDuplicateUrl" method="post">
              <input type="hidden" name="_token" :value="csrf">
              <OptionsItem icon="duplicate-solid" title="Duplicate" button-type="submit" :disabled="!allowNewProjects" />
            </form>

            <Modal v-if="isFreePlan && hasPermission(permissions.delete_project)" title="Remove project">
              <template #toggle>
                <OptionsItem icon="trash-solid" title="Remove" />
              </template>
              <template #body>
                <p class="tracking-wide mb-8">
                  Do you really want to delete project {{ app.name }}? <br><br> This action
                  can not be undone, once you delete this project you will no longer be able to reach it.
                </p>
                <form ref="modalDeleteAppForm" :action="appDeleteUrl" method="post">
                  <input type="hidden" name="_token" :value="csrf">
                  <input type="hidden" name="_method" value="delete">
                </form>
              </template>
              <template #footer>
                <Btn size="small" type="danger" @click="$refs.modalDeleteAppForm.submit()">
                  Remove
                </Btn>
              </template>
            </Modal>
          </template>
        </Options>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import OptionsDivider from './options/OptionsDivider'
import Options from './options/Options'
import OptionsItem from './options/OptionsItem'
import Modal from './Modal'
import Btn from './Btn'
import Icon from './Icon'
import UserAvatar from './UserAvatar.vue'

export default {
  components: {
    Icon,
    OptionsDivider,
    Options,
    OptionsItem,
    Modal,
    Btn,
    UserAvatar,
  },

  props: {
    permissions: {
      default: [],
    },
    userPermissions: {
      default: [],
    },
    appInitial: {
      default: {},
    },
    appDeleteUrl: {
      default: null,
    },
    appRedirectUrl: {
      default: null,
    },
    appEditorUrl: {
      default: null,
    },
    appSettingsUrl: {
      default: null,
    },
    appSeoUrl: {
      default: null,
    },
    appPlanUrl: {
      default: null,
    },
    appDuplicateUrl: {
      default: null,
    },
    allowNewProjects: {
      default: true,
    },
  },

  data() {
    return {
      csrf: this.$csrf,
      app: _.cloneDeep(this.appInitial),
      activeUsers: [],
    }
  },

  computed: {
    isFreePlan() {
      return this.app.plan === 'Free' || this.app.plan === 'Free website'
    },

    domain() {
      const defaultDomain = this.app.domains.find((domain) => domain.type === 'default' && domain.status === 'active' && domain.published_at !== null)
      if (defaultDomain) return defaultDomain.domain

      const publishedDomain = this.app.domains.find((domain) => domain.status === 'active' && domain.published_at !== null)
      if (publishedDomain) return publishedDomain.domain

      return null
    },

  },

  watch: {
    app: {
      immediate: true,
      handler() {
        const appPendingCheck = setInterval(() => {
          if (!this.app.pending) {
            clearInterval(appPendingCheck)
          } else {
            axios.get(`/api/v1/apps/${this.app.id}`).then(
              (res) => {
                if (res.data.status === 'created') {
                  this.app.pending = false
                }
              },
              (error) => {},
            )
          }
        }, 3000)
      },
    },
  },

  mounted() {
    axios.get(`/api/v1/apps/${this.appInitial.id}/users`).then((response) => {
      this.activeUsers = response.data
    })
  },

  methods: {
    hasPermission(permission) {
      return this.userPermissions.includes(permission)
    },
  },

}
</script>
