<template>
  <HeaderBar title="Fonts">
    <template #actions>
      <Btn
        class="lg:w-auto w-full"
        size="small"
        :disabled="isLoading"
        :icon-name="isLoading ? 'spinner' : ''"
        @click="handleUpdate"
      >
        Import current fonts
      </Btn>
    </template>
  </HeaderBar>
  <div class="bg-gray-500 rounded-lg px-4 mb-4">
    <div
      v-for="font in filteredList"
      :key="font.id"
      class="flex md:flex-row flex-col md:items-center py-8 space-y-3 md:space-y-0"
    >
      <div class="w-full flex items-center">
        {{ font.name }}
      </div>
      <div class="w-full">
        <div v-if="font.preview || font.tempPreview" v-html="font.preview || font.tempPreview" />
        <Spinner v-else />
      </div>
      <div v-if="font.preview" class="flex justify-center items-center w-full">
        Imported
      </div>
    </div>
  </div>
  <Pagination
    :total-list="fonts"
    :disable-next="!allFontsAreLoaded"
    :per-page="20"
    @update:slicedList="(slicedList) => filteredList = slicedList"
  />
  <Notification v-if="doneRequesting" :show="doneRequesting">
    <template #message>
      {{ importMessage }}
    </template>
  </Notification>
</template>

<script>
import axios from 'axios'
import fontToSvg from '../../../helpers/fonts/fontToSvg'
import Pagination from './Pagination.vue'

export default {
  components: {
    Pagination,
  },

  props: {
    fonts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      doneRequesting: false,
      importMessage: '',
      fontToSvg,
      filteredList: [],
    }
  },

  computed: {
    allFontsAreLoaded() {
      return this.filteredList.every((font) => font.preview || font.tempPreview)
    },
  },

  watch: {
    filteredList(fonts) {
      fonts.forEach((font) => {
        if (!font.preview) {
          this.fontToSvg.renderFont(font).then((svg) => {
            font.tempPreview = svg
          })
        }
      })
    },
  },

  methods: {
    handleUpdate() {
      this.isLoading = true
      const payload = this.filteredList.map((font) => ({
        id: font.id,
        preview: font.preview || font.tempPreview,
      }))

      axios.patch('/api/v1/fonts/batch-update', { fonts: payload })
        .then(({ data }) => {
          this.importMessage = data.message
          this.filteredList.map((font) => {
            if (font.tempPreview) {
              font.preview = font.tempPreview
              delete font.tempPreview
            }
            return font
          })
        })
        .catch((error) => {
          this.importMessage = error.response.data.message
        })
        .finally(() => {
          this.doneRequesting = true
          this.isLoading = false
        })
    },
  },
}
</script>
