<template>
  <div
    class="relative p-4 flex border first:rounded-t-lg last:rounded-b-lg transition duration-150 ease-in-out"
    :class="{
      'opacity-50': disabled,
      'bg-y-blue-500 bg-opacity-10 border-y-blue-500 border-opacity-50 z-10 ': isChecked,
      'border-gray-200': !isChecked,
    }"
  >
    <div class="flex items-center h-5">
      <input
        :id="id"
        type="radio"
        :name="name"
        :value="value"
        :checked="isChecked"
        :disabled="disabled"
        class="form-radio text-y-blue-500 h-4 w-4 transition duration-150 ease-in-out cursor-pointer focus:ring-0 focus:ring-opacity-0"
        @change="e => change(e)"
      >
    </div>

    <label
      :for="id"
      class="ml-3 flex flex-col cursor-pointer w-full"
    >
      <span class="block text-sm leading-5 tracking-wide text-black mb-1">
        {{ label }}
      </span>
      <span class="block text-xs leading-snug text-black text-opacity-50 tracking-wide">
        {{ description }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      default: '',
    },
    name: {
      default: '',
    },
    value: {
      default: '',
    },
    label: {
      default: '',
    },
    checked: {
      default: false,
    },
    description: {
      default: '',
    },
    disabled: {
      default: false,
    },
    radioStateIdentifier: {
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    isChecked() {
      return this.$store.state.radioValue === this.value
    },
  },
  mounted() {
    if (this.checked == 'true') this.$store.commit('setRadioValue', this.value)
  },
  beforeUnmount() {
    this.$store.commit('setRadioValue', null)
  },
  methods: {
    change(event) {
      this.$store.commit('setRadioValue', event.target.value)
      this.$emit('change')
    },
  },
}
</script>

<style scoped>

</style>
