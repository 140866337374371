<template>
  <Component
    :is="tag"
    class="focus:outline-none focus:shadow-none outline-none select-none tracking-tight text-center flex items-center justify-center transition duration-100 ease-in-out border font-medium antialiased"
    :href="href"
    :type="tag === 'button' && buttonType"
    :disabled="disabled"
    :class="[
      [types[type].style],
      [sizing[size].size],
      type === 'link' ? '' : hasSlotData ? [sizing[size].slot] : [sizing[size].noSlot],
      buttonType === 'button' ? 'inline-block' : '',
      disabled ? 'cursor-not-allowed opacity-50' : '',
      roundedFull ? '!rounded-full' : ''
    ]"
  >
    <Icon
      v-if="iconName"
      :class="[
        hasSlotData && !iconRight ? [sizing[size].iconSpaceLeft] : '',
        hasSlotData && iconRight ? [sizing[size].iconSpaceRight] : '',
        iconRight ? 'order-last' : ''
      ]"
      :name="iconName"
      color="CurrentFill"
      :size="[[sizing[size].iconSize]]"
    />
    <slot />
  </Component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      default: 'button',
    },
    type: {
      default: 'primary',
    },
    size: {
      default: 'default',
    },
    iconName: {
      default: '',
    },
    roundedFull: {
      default: false,
    },
    iconRight: {
      default: false,
    },
  },
  data() {
    return {
      sizing: {
        large: {
          size: 'rounded-[10px]', iconSize: '16', iconSpaceLeft: 'mr-2', iconSpaceRight: 'ml-2', noSlot: 'p-6', slot: 'px-6 py-3',
        },
        default: {
          size: 'rounded-[10px]', iconSize: '16', iconSpaceLeft: 'mr-2', iconSpaceRight: 'ml-2', noSlot: 'p-4', slot: 'px-6 py-3',
        },
        small: {
          size: 'text-sm leading-4 rounded-lg', iconSize: '12', iconSpaceLeft: 'mr-1.5', iconSpaceRight: 'ml-2', noSlot: 'p-2.5', slot: 'px-4 py-2',
        },
        xsmall: {
          size: 'text-sm leading-5 rounded-md', iconSize: '12', iconSpaceLeft: 'mr-1', iconSpaceRight: 'ml-1', noSlot: 'p-2', slot: 'px-3 py-1',
        },
        xxsmall: {
          size: 'text-xs rounded', iconSize: '12', iconSpaceLeft: 'mr-1', iconSpaceRight: 'ml-1', noSlot: 'p-1', slot: 'px-2 py-0.5',
        },
      },
      types: {
        primary: { style: 'border-transparent bg-black hover:bg-opacity-90 text-white' },
        ghost: { style: 'border-transparent bg-black lg:bg-opacity-0 bg-opacity-10 hover:bg-opacity-10 text-black' },
        secondary: { style: 'border-transparent bg-black bg-opacity-10 hover:bg-opacity-5 text-black' },
        'secondary-outline': { style: 'border-black border-opacity-10 hover:border-opacity-20 text-black' },
        white: { style: 'border-transparent bg-white hover:bg-opacity-90 text-black' },
        danger: { style: 'border-transparent bg-red-400 hover:bg-opacity-90 text-white' },
        'secondary-inverse': { style: 'border-transparent bg-black bg-opacity-50 hover:bg-opacity-75 text-white backdrop-filter backdrop-blur-xl' },
        link: { style: 'border-transparent text-black hover:text-opacity-80' },
        'link-white': { style: 'text-white hover:text-opacity-80 border-transparent' },
        'y-blue': { style: 'border-transparent bg-y-blue-500 hover:bg-[#1a8aff] text-white' },
      },
    }
  },
  computed: {
    hasSlotData() {
      return this.$slots.default
    },
    tag() {
      return this.href ? 'a' : 'button'
    },
  },
}
</script>

<style scoped>

</style>
