<template>
  <div class="flex flex-col items-center py-10 space-y-5">
    <span class="xl:w-2/4 text-center text-gray-600 tracking-wide block">{{ title }}</span>
    <slot name="actions" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
    },
  },
}
</script>

<style scoped>

</style>
