<template>
  <div
    class="group"
    :ycode-app-id="app.id"
    :class="isDisabled ? 'cursor-wait animate-pulse' : ''"
  >
    <div
      class="flex flex-col lg:flex-row lg:items-center gap-8 px-8 py-10 -mx-8 relative hover:bg-gray-100 rounded-lg"
      :class="isDisabled ? 'opacity-50 pointer-events-none' : 'cursor-pointer'"
      @click="goToBuilder"
    >
      <div class="flex-1 flex flex-col md:flex-row md:items-center gap-8">
        <div class="h-32 lg:h-40 w-56 lg:w-64 rounded-lg overflow-hidden relative shadow-xl group-hover:scale-[1.01] transition ease-in-out transform flex-shrink-0">
          <div v-if="activeUsers && activeUsers.length" class="flex absolute bottom-0 left-0 pl-2 pb-2 gap-1 z-20">
            <template v-for="activeUser of activeUsers" :key="activeUser.id">
              <UserAvatar
                :initials="activeUser.initials"
                :bg-color="activeUser.bg_color"
                :has-avatar="activeUser.hasAvatar"
                :avatar-url="activeUser.avatarUrl"
                size="tiny"
              />
            </template>
          </div>

          <div class="absolute top-0 left-0 w-full h-full z-10 border border-black border-opacity-10 rounded-lg" />

          <img v-if="app.thumbnail" :src="app.thumbnail" class="w-full h-full">

          <div v-if="!app.thumbnail" class="absolute bg-gray-200 w-full h-full flex items-center justify-center">
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 100 100"
              class="opacity-20"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Branding"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g id="white/y-filled" transform="translate(-152, -154)" fill="#343839">
                  <path id="y-symbol-filled" d="M199.420607,156 L199.420607,179.191002 L178.052,191.546 L204.135,206.613 L245.425888,182.722762 L245.425888,205.935451 L167.639179,250.943264 L167.541058,251 L167.433426,250.931583 L158,244.94904 L158,222.348201 L167.516528,227.801492 L183.796,218.381 L158,203.139831 L158,179.962781 L199.420607,156 Z" />
                </g>
              </g>
            </svg>
          </div>
        </div>

        <div class="flex-1 flex flex-col items-start gap-5">
          <div class="flex flex-col lg:flex-row items-start lg:justify-between w-full gap-3">
            <div class="flex flex-col items-start">
              <div class="flex items-center gap-1.5">
                <h5 class="text-lg truncate max-w-64 lg:max-w-72 xl:max-w-96">
                  {{ app.name }}
                </h5>
                <div class="flex gap-2 text-xxs bg-blue-100 text-blue-500 px-1 py-0.5 rounded-md font-semibold uppercase leading-3 transform hover:scale-105 transition ease-in-out duration-75">
                  {{ app.plan }}
                </div>
              </div>
              <div class="flex items-center gap-2.5 text-xs text-black tracking-wide">
                <span class="opacity-50">
                  <a
                    v-if="domain"
                    target="_blank"
                    :href="'https://' + domain"
                    class="block hover:underline"
                    @click.stop
                  >
                    {{ domain }}
                  </a>
                  <template v-else>Not published</template>
                </span>
              </div>
            </div>

            <div class="flex gap-2">
              <!--              <Btn v-if="isFreePlan && hasPermission(permissions.manage_project_billing)" :href="appMaster.routes.plan" size="small" type="secondary-outline">-->
              <!--                Upgrade-->
              <!--              </Btn>-->

              <!--              <template v-if="!isDisabled">-->
              <!--                <div v-if="hasActiveWalkthrough">-->
              <!--                  <Btn :href="`${appMaster.routes.redirect}/design`" size="small" type="secondary">-->
              <!--                    Edit-->
              <!--                  </Btn>-->
              <!--                </div>-->

              <!--                <div v-else-if="hasPermission(permissions.manage_project_general_settings)" class="flex">-->
              <!--                  <Options>-->
              <!--                    <template #toggle>-->
              <!--                      <Btn icon-name="triangle-combo" icon-right="true" size="small" type="secondary">-->
              <!--                        Edit-->
              <!--                      </Btn>-->
              <!--                    </template>-->
              <!--                    <template #items>-->
              <!--                      <OptionsItem title="Designer" classes="w-32" :min-width="false" :href="appMaster.routes.redirect" />-->
              <!--                      <OptionsItem title="Editor" classes="w-32" :min-width="false" :href="appMaster.routes.editor" />-->
              <!--                    </template>-->
              <!--                  </Options>-->
              <!--                </div>-->

              <!--                <div v-else>-->
              <!--                  <Btn :href="appMaster.routes.editor" size="small" type="secondary">-->
              <!--                    Edit-->
              <!--                  </Btn>-->
              <!--                </div>-->
              <!--              </template>-->

              <Options v-if="hasOptionsPermissions">
                <template #toggle>
                  <Btn icon-name="more" size="small" type="secondary" />
                </template>
                <template #items>
                  <OptionsItem
                    v-if="hasPermission(permissions.manage_project_general_settings)"
                    icon="cog-solid"
                    title="Settings"
                    :href="appMaster.routes.settings"
                  />

                  <OptionsDivider v-if="hasPermission(permissions.duplicate_project) || (isFreePlan && hasPermission(permissions.delete_project))" />

                  <form v-if="hasPermission(permissions.duplicate_project)" ref="modalDeleteAppForm" :action="appMaster.routes.duplicate" method="post">
                    <input type="hidden" name="_token" :value="csrf">
                    <OptionsItem icon="duplicate-solid" title="Duplicate" button-type="submit" :disabled="!allowNewProjects" />
                  </form>

                  <Modal v-if="isFreePlan && hasPermission(permissions.delete_project)" title="Remove project">
                    <template #toggle>
                      <OptionsItem icon="trash-solid" title="Remove" />
                    </template>
                    <template #body>
                      <p class="tracking-wide mb-8">
                        Do you really want to delete project {{ app.name }}? <br><br> This action
                        can not be undone, once you delete this project you will no longer be able to reach it.
                      </p>
                      <form ref="modalDeleteAppForm" :action="appMaster.routes.destroy" method="post">
                        <input type="hidden" name="_token" :value="csrf">
                        <input type="hidden" name="_method" value="delete">
                      </form>
                    </template>
                    <template #footer>
                      <Btn size="small" type="danger" @click="$refs.modalDeleteAppForm.submit()">
                        Remove
                      </Btn>
                    </template>
                  </Modal>
                </template>
              </Options>
            </div>
          </div>

          <div class="hidden lg:flex items-center flex-wrap w-full border-t pt-5 gap-x-5 gap-y-2">
            <div class="flex gap-2">
              <span class="text-xs font-semibold opacity-50">Pages</span>
              <span class="text-xs font-medium opacity-80">{{ appMaster.stats?.pages }} / {{ ((appMaster.restrictions?.pages == -1) || (appMaster.restrictions?.pages === 9999999)) ? '∞' : appMaster.restrictions?.pages }}</span>
            </div>

            <div class="h-0.5 w-0.5 rounded-full bg-black opacity-75" />

            <div class="flex gap-2">
              <span class="text-xs font-semibold opacity-50">CMS items</span>
              <span class="text-xs font-medium opacity-80">{{ appMaster.stats?.database_entries }} / {{ appMaster.restrictions?.database_entries }}</span>
            </div>

            <div class="h-0.5 w-0.5 rounded-full bg-black opacity-75" />

            <div class="flex gap-2">
              <span class="text-xs font-semibold opacity-50">Collections</span>
              <span class="text-xs font-medium opacity-80">{{ appMaster.stats?.cms_collections }} / {{ appMaster.restrictions?.cms_collections }}</span>
            </div>

            <div class="h-0.5 w-0.5 rounded-full bg-black opacity-75" />

            <div class="flex gap-2">
              <span class="text-xs font-semibold opacity-50">Bandwidth</span>
              <span class="text-xs font-medium opacity-80">{{ appMaster.stats?.file_storage }} / {{ bytesToGb(appMaster.restrictions?.file_storage) }}GB</span>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:hidden flex items-center flex-wrap w-full border-t pt-5 gap-x-5 gap-y-2">
        <div class="flex gap-2">
          <span class="text-xs font-semibold opacity-50">Pages</span>
          <span class="text-xs font-medium opacity-80">{{ appMaster.stats?.pages }} / {{ ((appMaster.restrictions?.pages == -1) || (appMaster.restrictions?.pages === 9999999)) ? '∞' : appMaster.restrictions?.pages }}</span>
        </div>

        <div class="h-0.5 w-0.5 rounded-full bg-black opacity-75" />

        <div class="flex gap-2">
          <span class="text-xs font-semibold opacity-50">CMS items</span>
          <span class="text-xs font-medium opacity-80">{{ appMaster.stats?.database_entries }} / {{ appMaster.restrictions?.database_entries }}</span>
        </div>

        <div class="h-0.5 w-0.5 rounded-full bg-black opacity-75" />

        <div class="flex gap-2">
          <span class="text-xs font-semibold opacity-50">Collections</span>
          <span class="text-xs font-medium opacity-80">{{ appMaster.stats?.cms_collections }} / {{ appMaster.restrictions?.cms_collections }}</span>
        </div>

        <div class="h-0.5 w-0.5 rounded-full bg-black opacity-75" />

        <div class="flex gap-2">
          <span class="text-xs font-semibold opacity-50">Bandwidth</span>
          <span class="text-xs font-medium opacity-80">{{ appMaster.stats?.file_storage }} / {{ bytesToGb(appMaster.restrictions?.file_storage) }}GB</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import OptionsDivider from './options/OptionsDivider'
import Options from './options/Options'
import OptionsItem from './options/OptionsItem'
import Modal from './Modal'
import Btn from './Btn'
import UserAvatar from './UserAvatar.vue'

export default {
  components: {
    OptionsDivider,
    Options,
    OptionsItem,
    Modal,
    Btn,
    UserAvatar,
  },

  props: {
    user: {
      default: null,
    },
    appMaster: {
      default: {},
    },
    permissions: {
      default: [],
    },
    userPermissions: {
      default: [],
    },
    allowNewProjects: {
      default: true,
    },
  },

  data() {
    return {
      csrf: this.$csrf,
      activeUsers: [],
      app: this.appMaster.data,
    }
  },

  computed: {
    hasOptionsPermissions() {
      return this.hasPermission(this.permissions.manage_project_general_settings)
        || this.hasPermission(this.permissions.manage_seo)
        || this.hasPermission(this.permissions.manage_project_billing)
        || this.hasPermission(this.permissions.duplicate_project)
        || (this.isFreePlan && this.hasPermission(this.permissions.delete_project))
    },

    isFreePlan() {
      return this.app.plan === 'Free' || this.app.plan === 'Free website'
    },

    domain() {
      const primaryDomain = this.app.domains.find((domain) => domain.status === 'active' && domain.published_at !== null && domain.primary === true)
      if (primaryDomain) return primaryDomain.domain

      const customDomain = this.app.domains.find((domain) => domain.type === 'custom' && domain.status === 'active' && domain.published_at !== null)
      if (customDomain) return customDomain.domain

      const defaultDomain = this.app.domains.find((domain) => domain.type === 'default' && domain.status === 'active' && domain.published_at !== null)
      if (defaultDomain) return defaultDomain.domain

      return null
    },

    hasActiveWalkthrough() {
      return (
        this.app.walkthrough_step !== null
        && this.app.walkthrough_step !== 999
      )
    },

    isAppOwner() {
      return this.app.created_by_id === this.user.id
    },

    isNotOwnerOfActiveWalkthroughApp() {
      return this.hasActiveWalkthrough && !this.isAppOwner
    },

    isDisabled() {
      return this.app.pending || this.isNotOwnerOfActiveWalkthroughApp
    },
  },

  watch: {
    app: {
      immediate: true,
      handler() {
        const appPendingCheck = setInterval(() => {
          if (!this.app.pending) {
            clearInterval(appPendingCheck)
          } else {
            axios.get(`/api/v1/apps/${this.app.id}`).then(
              (res) => {
                if (res.data.status === 'created') {
                  this.app.pending = false
                }
              },
              (error) => {},
            )
          }
        }, 3000)
      },
    },
  },

  mounted() {
    axios.get(`/api/v1/apps/${this.app.id}/users`).then((response) => {
      this.activeUsers = response.data
    })
  },

  methods: {
    hasPermission(permission) {
      return this.userPermissions.includes(permission)
    },

    bytesToGb(bytes) {
      return (bytes / (1000 * 1000 * 1000)).toFixed(1)
    },

    goToBuilder() {
      document.location.href = this.hasActiveWalkthrough ? `${this.appMaster.routes.builder}/design` : this.appMaster.routes.builder
    },
  },

}
</script>
