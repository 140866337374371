<template>
  <label :for="labelFor" class="text-black font-medium">
    {{ title }}
  </label>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
    },
    labelFor: {
      default: '',
    },
  },
}
</script>

<style scoped>

</style>
