<template>
  <template v-if="type === 'list'">
    <div v-if="hasAvatar" class="h-7 w-7 rounded-full border-2 border-white relative z-20">
      <div class="w-full h-full absolute border border-white border-opacity-10 z-10 rounded-full" />
      <img :src="avatarUrl" class="w-full h-full absolute rounded-full object-fill">
    </div>

    <div v-else class="rounded-full h-7 w-7 flex justify-center items-center border-2 border-white text-xs bg-cover z-20 mr-1" :style="`background-color: ${bgColor}`">
      {{ initials }}
    </div>
  </template>

  <template v-else-if="type === 'builder'">
    <div v-if="hasAvatar" class="w-full h-full rounded-md relative">
      <div class="w-full h-full absolute border border-white border-opacity-10 z-10 rounded-md" />
      <img :src="avatarUrl" class="w-full h-full absolute rounded-md object-fill">
    </div>

    <div v-else class="w-full h-full rounded-md bg-cover text-black flex justify-center items-center text-xxs font-medium" :style="`background-color: ${bgColor}`">
      {{ initials }}
    </div>
  </template>

  <template v-else-if="type === 'builder-2'">
    <div v-if="hasAvatar" class="h-7 w-7 my-1 rounded-md relative">
      <div class="w-full h-full absolute border border-white border-opacity-10 z-10 rounded-md" />
      <img :src="avatarUrl" class="w-full h-full absolute rounded-md object-fill">
    </div>

    <div v-else class="h-7 w-7 my-1 rounded-md bg-cover relative" :style="`background-color: ${bgColor}`">
      <div class="w-full h-full absolute rounded-md text-black flex justify-center items-center text-xxs font-medium">
        {{ initials }}
      </div>
    </div>
  </template>

  <template v-else-if="type === 'builder-3'">
    <div v-if="hasAvatar" class="h-7 w-7 rounded-md relative">
      <div class="w-full h-full absolute border border-white border-opacity-10 z-10 rounded-md" />
      <img :src="avatarUrl" class="w-full h-full absolute rounded-md object-fill">
    </div>

    <div v-else class="h-7 w-7 rounded-md bg-cover relative" :style="`background-color: ${bgColor}`">
      <div class="w-full h-full absolute rounded-md text-black flex justify-center items-center text-xxs font-medium">
        {{ initials }}
      </div>
    </div>
  </template>

  <template v-else>
    <div v-if="hasAvatar">
      <img :src="avatarUrl" :class="classes" class="object-fill">
    </div>

    <span v-else :class="classes" :style="`background-color: ${bgColor}`">{{ initials }}</span>
  </template>
</template>

<script>
export default {
  props: {
    initials: { type: String },
    bgColor: { type: String },
    hasAvatar: { type: Boolean },
    avatarUrl: { type: String },
    size: { type: String, default: null },
    type: { type: String, default: null },
  },
  computed: {
    classes() {
      let classes = 'flex justify-center items-center font-medium'
      if (this.size === 'big') {
        classes = `h-10 w-10 text-sm rounded-lg ${classes}`
      }
      if (this.size === 'small') {
        classes = `h-7 w-7 rounded ${classes}`
      }
      if (this.size === 'tiny') {
        classes = `h-6 w-6 rounded text-xxs ${classes}`
      }

      return classes
    },
  },
}
</script>
