import makerjs from 'makerjs'
import opentype from 'opentype.js'

export default class App {
  static renderFont(font) {
    return App.render(
      font,
      'regular',
      font.data.family,
      15,
      true,
      true,
      true,
      false,
      undefined,
      'cm',
      '#fff',
      '#fff',
      '0',
      true,
      'evenodd',
    )
  }

  static render(font, variant, text, size, union, filled, kerning, separate, bezierAccuracy, units, fill, stroke, strokeWidth, strokeNonScaling, fillRule) {
    const variantUrl = font.data.files[variant] || Object.values(font.data.files)[0]
    const url = variantUrl.replace('http:', 'https:')

    return new Promise((resolve, reject) => {
      opentype.load(url, (err, font) => {
        if (err) {
          reject(err.toString())
        } else {
          resolve(App.callMakerjs(font, text, size, union, filled, kerning, separate, bezierAccuracy, units, fill, stroke, strokeWidth, strokeNonScaling, fillRule))
        }
      })
    })
  }

  static callMakerjs(font, text, size, union, filled, kerning, separate, bezierAccuracy, units, fill, stroke, strokeWidth, strokeNonScaling, fillRule) {
    const textModel = new makerjs.models.Text(font, text, size, union, false, bezierAccuracy, { kerning })
    if (separate) {
      for (const i in textModel.models) {
        textModel.models[i].layer = i
      }
    }
    return makerjs.exporter.toSVG(textModel, {
      fill: filled ? fill : undefined,
      stroke: stroke || undefined,
      strokeWidth: strokeWidth || undefined,
      fillRule: fillRule || undefined,
      scalingStroke: !strokeNonScaling,
    })
  }
}
