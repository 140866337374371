<template>
  <div v-if="(radioValue !== 'Admin') && (radioValue !== 'Owner')">
    <FieldLabel title="Projects" class="block" />

    <CheckboxGroup v-if="projects.length > 0">
      <CheckboxGroupItem
        v-for="app in projects"
        :id="app.id"
        :key="app.id"
        name="projects[]"
        :value="app.id"
        :label="app.name"
        :checked="userApps.includes(app.id)"
      />
    </CheckboxGroup>
    <span
      v-else
      class="text-sm"
    >
      There are no projects yet.
    </span>
  </div>
</template>

<script>
export default {
  props: {
    projects: {
      type: Array,
    },
    userApps: {
      type: Array,
    },
    roles: {
      type: String,
      default: null,
    },
  },

  computed: {
    radioValue() {
      return this.$store.state.radioValue
    },
  },
}
</script>
