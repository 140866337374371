<template>
  <span
    class="block text-red-400 tracking-wide mt-1 text-xs"
  >
    {{ message }}
  </span>
</template>

<script>
export default {
  props: {
    message: {
      default: null,
    },
  },

  data() {
    return {}
  },
}
</script>

<style scoped>

</style>
