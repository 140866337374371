<template>
  <div class="space-y-6">
    <div class="w-full overflow-hidden rounded-md shadow">
      <div class="slider-transition" :style="{ width: innerWidth + '%', marginLeft: innerMargin }">
        <article
          v-for="(image, key) in images"
          :key="key"
          :style="{ width: articleWidth }"
          class="float-left"
          style="height: 640px;"
        >
          <img :src="image.image" class="w-full shadow bg-cover">
        </article>
      </div>
    </div>

    <div class="flex items-center justify-between w-full">
      <div class="w-1/3">
        <Btn size="xsmall" type="ghost" icon-name="arrow-left" @click="previous">
          Previous
        </Btn>
      </div>

      <div class="flex justify-center w-1/3 space-x-3">
        <span
          v-for="(image, key) in images"
          :key="key"
          class="bg-black h-1 w-1 rounded-full"
          :class="currentIndex !== key ? 'bg-opacity-20' : ''"
        />
      </div>

      <div class="flex justify-end w-1/3">
        <Btn
          size="xsmall"
          type="ghost"
          icon-name="arrow-right"
          icon-right="true"
          @click="next"
        >
          Next
        </Btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    images: {
      default: [],
    },
  },

  data() {
    return {
      currentIndex: 0,
      innerWidth: (this.images.length * 100),
    }
  },

  computed: {
    innerMargin() {
      if (this.currentIndex === 0) return 0
      return `-${ this.currentIndex * 100 }%`
    },
    articleWidth() {
      return `${100 / this.images.length }%`
    },
  },

  methods: {
    next() {
      if (this.currentIndex < (this.images.length - 1)) {
        this.currentIndex += 1
      }
    },

    previous() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1
      }
    },
  },

}
</script>

<style scoped>

.slider-transition {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  -webkit-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -ms-transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition: all 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

  -webkit-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -o-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  -ms-transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000);
}

</style>
