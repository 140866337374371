<template>
  <div class="group flex flex-col gap-2.5">
    <div class="rounded-[10px] overflow-hidden">
      <div
        class="lg:group relative bg-no-repeat bg-cover bg-top relative rounded-[10px]"
        :class="[
          [sizing[size].size],
        ]"
      >
        <div class="bg-black bg-opacity-75 w-full h-full absolute group-hover:opacity-100 lg:opacity-0 flex flex-col items-center justify-center gap-3 transition duration-100 ease-in-out rounded-[10px] z-40">
          <slot name="actions" />
        </div>
        <div class="border border-black rounded-[10px] h-full w-full absolute z-10 opacity-10" />
        <div class="w-full h-full bg-cover transform group-hover:scale-[1.02] transition duration-300 ease-in-out rounded-[10px] bg-top" :style="{ backgroundImage: 'url(' + image + ')' }" />
      </div>
    </div>
    <span class="text-sm font-medium">
      {{ title }}
    </span>
    <span v-if="description" class="block text-sm text-black opacity-50 tracking-wide leading-snug">{{ description }}</span>
    <div v-if="authorName" class="flex items-center space-x-1.5 mt-2">
      <div class="bg-black h-4 w-4 rounded bg-cover" :style="{ backgroundImage: 'url(' + authorPhoto + ')' }" />
      <span class="block text-xs text-black opacity-75 tracking-wide leading-snug">{{ authorName }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
    },
    description: {
      default: '',
    },
    size: {
      default: 'default',
    },
    image: {
      default: '',
    },
    authorName: {
      default: null,
    },
    authorPhoto: {
      default: null,
    },
  },
  data() {
    return {
      sizing: {
        default: {
          size: 'h-72 xl:h-80',
        },
        small: {
          size: 'h-48',
        },

      },
    }
  },
}
</script>
