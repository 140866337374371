<template>
  <div v-if="show" class="fixed left-0 top-0 w-full h-screen z-50 cursor-default" @click.stop="show = false" />
  <div class="relative">
    <div class="h-full" @click.stop="show = true">
      <slot name="toggle" />
    </div>
    <Transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="show"
        class="bg-white border border-gray-200 rounded-[10px] shadow-2xl shadow-gray-900/10 absolute py-1 whitespace-nowrap z-50 mt-2"
        :class="[
          isRight ? 'right-0' : 'left-0',
          fixedWidth ? 'max-w-64' : '',
        ]"
      >
        <ul v-if="$slots.items" @click.stop="handleClick">
          <slot name="items" />
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {

  props: {
    isRight: {
      default: true,
    },
    fixedWidth: {
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      show: false,
    }
  },

  methods: {
    handleClick() {
      if (this.closeOnSelect) {
        this.show = false
      }
    },
  },
}
</script>

<style scoped>

</style>
