<template>
  <Component
    :is="tag"
    :type="tag === 'button' && buttonType"
    :href="href"
    class="flex items-center space-x-1.5 px-4 py-1.5 text-sm font-medium leading-5 text-black text-opacity-75 hover:bg-gray-200 hover:bg-opacity-50 focus:outline-none w-full text-left group"
    :class="{
      'cursor-not-allowed opacity-50 pointer-events-none': disabled,
      [classes]: true ,
      'min-w-48': minWidth,
    }"
  >
    <slot>
      <Icon
        v-if="icon"
        :name="icon"
        class="opacity-50"
        size="12"
      />
      <span>{{ title }}</span>
    </slot>
  </Component>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
    },
    href: {
      type: String,
    },
    buttonType: {
      default: 'button',
    },
    icon: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
    },
    minWidth: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    tag() {
      return this.href ? 'a' : 'button'
    },
  },
}
</script>

<style scoped>

</style>
