<template>
  <svg
    width="23px"
    height="24px"
    viewBox="0 0 23 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Authentication"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Signup-1" transform="translate(-789.000000, -998.000000)" fill="#000000">
        <path id="y-symbol-filled" d="M799.464153,998 L799.464153,1003.85878 L794.066,1006.98 L800.655573,1010.7868 L798.991,1011.749 L811.08654,1004.75101 L811.08654,1010.61527 L791.435161,1021.98567 L791.410373,1022 L791.383181,1021.98272 L789,1020.47134 L789,1014.76165 L791.404176,1016.13932 L795.516,1013.76 L789,1009.90901 L789,1004.05376 L799.464153,998 Z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BrandYcode',
}
</script>

<style scoped>

</style>
