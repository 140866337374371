<template>
  <a
    :href="href"
    class="flex items-center space-x-3 px-5 py-1.5 font-medium text-sm text-black select-none cursor-pointer transition duration-100 ease-in-out"
    :class="[
      isActive ? '' : 'text-opacity-75 hover:text-opacity-100'
    ]"
  >
    <div
      class="w-full flex items-center px-3 py-2 rounded-lg gap-2"
      :class="[
        isActive ? 'bg-gray-150' : ''
      ]"
    >
      <Icon
        v-if="icon"
        :name="icon"
        :class="[
          isActive ? '' : 'opacity-50'
        ]"
        size="16"
      />
      <span>{{ title }}</span>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
    },
    icon: {
      default: null,
    },
    href: {
      default: '#',
    },
    isActive: {
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
