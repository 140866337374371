<template>
  <div class="flex items-center justify-between pb-6">
    <Options :is-right="false" :fixed-width="false" :close-on-select="true">
      <template #toggle>
        <div class="w-[220px] px-4 py-2.5 rounded-[10px] border border-transparent bg-gray-900/5 flex items-center">
          <span class="text-sm font-medium">{{ selectedCategory }}</span>
          <Icon :name="loadingCategory ? 'loader' : 'triangle-combo'" size="12" class="ml-auto opacity-25" />
        </div>
      </template>

      <template #items>
        <div class="grid grid-cols-3 w-[520px] py-0.5 px-1.5 gap-1">
          <span
            class="group flex items-center gap-1.5 px-2 py-1.5 text-sm font-medium rounded-lg"
            :class="{
              'bg-black/5': (category === null),
              'hover:bg-black/5 text-black/70': !(category === null),
            }"
            @click="changeCategory(null)"
          >
            <Icon
              name="collection-solid"
              size="12"
              :class="{
                'opacity-70': (category === null),
                'group-hover:opacity-70 opacity-50': !(category === null),
              }"
            />
            <span>All</span>
          </span>

          <span
            v-for="selectableCategory in categories"
            :key="selectableCategory.id"
            class="group flex items-center gap-1.5 px-2 py-1.5 text-sm font-medium rounded-lg"
            :class="{
              'bg-black/5': (selectableCategory.id === category),
              'hover:bg-black/5 text-black/70': !(selectableCategory.id === category),
            }"
            @click="changeCategory(selectableCategory.id)"
          >
            <Icon
              :name="selectableCategory.icon"
              size="12"
              :class="{
                'opacity-70': (selectableCategory.id === category),
                'group-hover:opacity-70 opacity-50': !(selectableCategory.id === category),
              }"
            />
            <span>{{ selectableCategory.name }}</span>
          </span>
        </div>
      </template>
    </Options>

    <Options :close-on-select="true">
      <template #toggle>
        <div class="w-[120px] px-4 py-2.5 rounded-[10px] border border-black/5 flex items-center">
          <span class="text-sm font-medium">{{ selectedSortBy.label }}</span>
          <Icon :name="loadingSortBy ? 'loader' : 'triangle-combo'" size="12" class="ml-auto opacity-25" />
        </div>
      </template>

      <template #items>
        <OptionsItem
          v-for="sortByOption in sortByOptions"
          :key="sortByOption.value"
          :title="sortByOption.label"
          @click.passive="changeSortBy(sortByOption)"
        />
      </template>
    </Options>
  </div>

  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
    <div class="group flex flex-col gap-2.5">
      <div class="h-[320px] w-full border border-black rounded-[10px] border-opacity-10 group-hover:border-opacity-20 flex items-center justify-center relative transition duration-100 ease-in-out">
        <div class="bg-white rounded-[10px] bg-opacity-80 w-full h-full absolute group-hover:opacity-100 lg:opacity-0 flex items-center justify-center space-x-3 transition duration-100 ease-in-out">
          <Modal title="Name your project">
            <template #toggle>
              <Btn size="small">
                Start from scratch
              </Btn>
            </template>

            <template #body>
              <form ref="modalStartFromScratchForm" :action="urlAppsCreate" method="post">
                <input type="hidden" name="_token" :value="csrf">
                <TextField
                  :required="true"
                  :autofocus="true"
                  :name="'app_name'"
                  :field-id="'app_name'"
                  :placeholder="'My successful project name'"
                />
              </form>
            </template>

            <template #footer>
              <Btn
                size="small"
                :disabled="disabled"
                :icon-name="disabled ? 'loader' : ''"
                @click="submitForm($refs.modalStartFromScratchForm)"
              >
                Continue
              </Btn>
            </template>
          </Modal>
        </div>

        <h5 class="text-black text-opacity-20 text-2xl font-bold">
          Blank canvas
        </h5>
      </div>

      <h5 class="text-sm font-medium">
        Start from scratch
      </h5>
    </div>

    <TemplateCard
      v-for="template in templatesPagination.data"
      :key="template.id"
      :title="template.name"
      :image="template.thumbnail"
    >
      <template #actions>
        <Modal title="Name your project">
          <template #toggle>
            <Btn href="#" size="small" type="white">
              Use template
            </Btn>
          </template>
          <template #body>
            <form :ref="`modalTemplateForm${template.id}`" :action="getTemplateSelectAction(template)" method="post">
              <input type="hidden" name="_token" :value="csrf">
              <TextField
                :required="true"
                :autofocus="true"
                :name="'app_name'"
                :field-id="'app_name'"
                :placeholder="'My successful project name'"
              />
            </form>
          </template>
          <template #footer>
            <Btn
              size="small"
              :disabled="disabled"
              :icon-name="disabled ? 'loader' : ''"
              @click="submitForm($refs[`modalTemplateForm${template.id}`])"
            >
              Continue
            </Btn>
          </template>
        </Modal>
        <Btn :href="getTemplatePreviewLink(template)" size="small" type="link-white" target="_blank">
          Preview
        </Btn>
      </template>
    </TemplateCard>
  </div>

  <div class="py-16 flex justify-center">
    <Btn
      v-if="!isLastPage"
      size="small"
      type="secondary"
      :disabled="isLoading"
      :icon-name="isLoading ? 'loader' : ''"
      @click="loadMore"
    >
      Load more
    </Btn>
  </div>
</template>

<script>
import axios from 'axios'
import Btn from './Btn.vue'
import Icon from './Icon.vue'
import Modal from './Modal.vue'
import TextField from './forms/TextField.vue'
import Options from './options/Options.vue'
import OptionsItem from './options/OptionsItem.vue'
import TemplateCard from './cards/TemplateCard.vue'

export default {
  components: {
    Icon,
    Btn,
    Modal,
    Options,
    TextField,
    OptionsItem,
    TemplateCard,
  },

  props: {
    csrf: {
      type: String,
      default: null,
    },
    templates: {
      default: [],
    },
    categories: {
      default: [],
    },
    currentUrl: {
      type: String,
      default: null,
    },
    urlAppsCreate: {
      type: String,
      default: null,
    },
    urlUpgrade: {
      type: String,
    },
    hasAvailableProjects: {
      type: Boolean,
      default: false,
    },
    urlTemplateSelect: {
      type: String,
      default: null,
    },
    urlTemplatePreview: {
      type: String,
      default: null,
    },
    urlCategoryShow: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      limit: 15,
      sortBy: 'new',
      category: null,
      disabled: false,
      isLoading: false,
      loadingSortBy: false,
      loadingCategory: false,
      sortByOptions: [
        { value: 'new', label: 'New' },
        { value: 'popular', label: 'Popular' },
      ],
      templatesPagination: _.cloneDeep(this.templates),
    }
  },

  computed: {
    selectedSortBy() {
      return this.sortByOptions.find((sortByOption) => sortByOption.value === this.sortBy)
    },

    selectedCategory() {
      if (!this.category) return 'All templates'

      return this.categories.find((category) => category.id == this.category).name
    },

    isLastPage() {
      return this.templatesPagination?.next_page_url === null
    },
  },

  methods: {
    changeCategory(category) {
      this.limit = 15
      this.category = category
      this.loadingCategory = true

      axios.get(`/api/v1/templates?sort_by=${ this.sortBy }&limit=15&category=${ this.category }`)
        .then(({ data }) => this.templatesPagination = data)
        .catch((error) => {})
        .finally(() => this.loadingCategory = false)
    },

    changeSortBy(sortBy) {
      this.loadingSortBy = true
      this.sortBy = sortBy.value

      axios.get(`/api/v1/templates?sort_by=${ this.sortBy }&limit=15&category=${ this.category }`)
        .then(({ data }) => this.templatesPagination = data)
        .catch((error) => {})
        .finally(() => this.loadingSortBy = false)
    },

    loadMore() {
      this.limit += 12
      this.isLoading = true

      axios.get(`/api/v1/templates?sort_by=${ this.sortBy }&limit=${ this.limit }&category=${ this.category }`)
        .then(({ data }) => this.templatesPagination = data)
        .catch((error) => {})
        .finally(() => this.isLoading = false)
    },

    getTemplateSelectAction(template) {
      const urlTemplateSelect = _.cloneDeep(this.urlTemplateSelect)
      return urlTemplateSelect.replace('TEMPLATE_ID', template.id)
    },

    getTemplatePreviewLink(template) {
      const urlTemplatePreview = _.cloneDeep(this.urlTemplatePreview)
      return urlTemplatePreview.replace('TEMPLATE_ID', template.id)
    },

    getCategoryLink(category) {
      const urlCategoryShow = _.cloneDeep(this.urlCategoryShow)
      return urlCategoryShow.replace('CATEGORY_SLUG', category.slug)
    },

    submitForm(parentForm) {
      if (Array.isArray(parentForm)) parentForm = parentForm[0]

      if (parentForm.checkValidity()) {
        this.disabled = true
        parentForm.submit()
      } else {
        parentForm.reportValidity()
      }
    },
  },
}
</script>
