<template>
  <div>
    <Transition
      enter-active-class="transform ease-out duration-300 transition"
      enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="showNotification">
        <div
          :class="[
            [types[type].style],
            type,
          ]"
          class="border-l p-4"
        >
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <svg
                v-if="type === 'success'"
                class="h-5 w-5 text-green-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <svg
                v-else-if="type === 'warning'"
                class="h-5 w-5 text-yellow-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
              <Icon v-else-if="type === 'neutral'" name="info-solid" class="text-black text-opacity-50" size="16" />
            </div>
            <div class="ml-3">
              <p
                :class="[
                  [types[type].text],
                ]"
                class="text-sm"
              >
                <slot />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: 'success',
    },
    show: {
      default: true,
    },
  },
  data() {
    return {
      showNotification: this.show,
      types: {
        success: { style: 'bg-green-50 border-green-400', text: 'text-green-700' },
        error: { style: 'bg-red-50 border-red-400', text: 'text-red-700' },
        warning: { style: 'bg-yellow-50 border-yellow-400', text: 'text-yellow-700' },
        neutral: { style: 'bg-black bg-opacity-5 border-black border-opacity-25', text: 'text-black' },
      },
    }
  },
}
</script>

<style lang="scss">
.neutral a {
    @apply font-medium underline text-black hover:text-opacity-75;
}

.warning a {
  @apply font-medium underline text-yellow-700 hover:text-yellow-600;
}
</style>
