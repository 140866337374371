<template>
  <div>
    <slot name="password" v-bind="this" />
  </div>
</template>
<script>
export default {
  props: ['min', 'regex', 'errors'],

  data() {
    return {
      passwordFormVisible: false,
      password: '',
      rules: {
        passwordLength: false,
        // regex rules added dynamically on created()
      },
      revealPassword: false,
    }
  },

  watch: {
    password(current, old) {
      _.forEach(this.regex, (value, key) => {
        this.rules[_.camelCase(value)] = new RegExp(key).test(current)
      })

      this.rules.passwordLength = current.length >= this.min
    },
  },

  created() {
    _.forEach(this.regex, (value, key) => {
      this.rules[_.camelCase(value)] = false
    })

    this.passwordFormVisible = this.errors // if we have errors, make it visible
  },

  methods: {
    showPasswordForm() {
      this.passwordFormVisible = true
    },
    togglePasswordReveal() {
      this.revealPassword = !this.revealPassword
    },
  },
}
</script>
