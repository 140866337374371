<template>
  <div class="flex flex-col items-start gap-2.5">
    <div v-for="index in rowsToRender" :key="index" class="w-full">
      <div class="w-full relative flex items-center">
        <TextField
          :ref="'email_' + index"
          field-id="email"
          type="email"
          :name="'invites[' + index + '][email]'"
          placeholder="Email address"
          input-class="pr-24"
        />

        <div class="absolute right-4">
          <Options :close-on-select="true">
            <template #toggle>
              <div class="flex items-center gap-1 text-sm font-medium text-black opacity-50 hover:opacity-75 transition ease-in-out cursor-pointer duration-75">
                <span>{{ selectedRoles[index] }}</span>
                <Icon name="triangle-combo" size="10" />
              </div>
            </template>
            <template #items>
              <OptionsItem v-for="role in roles" :key="role.id" :title="role.label" @click="setRole(role, index)" />
            </template>
          </Options>

          <input
            type="hidden"
            :name="'invites[' + index + '][role]'"
            class="mt-1"
            :value="selectedRolesValues[index]"
          >
        </div>
      </div>

      <span v-if="errors['invites.' + index + '.email']" class="text-sm text-red-400">{{ errors['invites.' + index + '.email'][0] }}</span>
    </div>

    <Btn size="small" type="link" icon-name="plus" @click="add">
      Add more
    </Btn>
  </div>
</template>

<script>
export default {
  props: {
    roles: {},
    errors: {},
  },

  data() {
    return {
      rowsToRender: 3,
      selectedRoles: {
        1: this.roles[1].label,
        2: this.roles[1].label,
        3: this.roles[1].label,
      },
      selectedRolesValues: {
        1: this.roles[1].name,
        2: this.roles[1].name,
        3: this.roles[1].name,
      },
    }
  },

  methods: {
    add() {
      this.rowsToRender++

      this.selectedRoles[this.rowsToRender] = this.roles[1].label
      this.selectedRolesValues[this.rowsToRender] = this.roles[1].label
    },

    setRole(role, index) {
      this.selectedRoles[index] = role.label
      this.selectedRolesValues[index] = role.name
    },
  },
}
</script>

<style scoped>

</style>
