<template>
  <hr class="border-gray-200 my-1">
</template>

<script>
export default {
  name: 'OptionsDivider',
}
</script>

<style scoped>

</style>
