<template>
  <Modal
    :show="showModal"
    :show-close-btn="false"
    title="Latest updates"
    :fixed-width="false"
    @close="modalClosed"
  >
    <template #body>
      <div class="flex flex-col gap-1 -mx-5 -my-5">
        <a
          v-for="update in updates"
          :key="update._ycode_id"
          class="group flex items-center gap-6 p-5 rounded-2xl hover:bg-gray-100 transition ease-in-out"
          :href="'https://www.ycode.com/updates/' + update.Slug"
          target="_blank"
        >

          <div class="shrink-0 rounded-[10px] h-[160px] overflow-hidden cursor-pointer">
            <img :src="update.Image" class="rounded-[10px] h-[160px] group-hover:scale-[1.02] transition ease-in-out cursor-pointer">
          </div>

          <div class="flex flex-col py-2">

            <span class="tracking-tight leading-tight text-lg text-black font-semibold font-inter mb-1.5">
              {{ update.Name }}
            </span>
            <div class="text-sm leading-relaxed text-black/60 mb-3" v-html="update.Intro.replace(/<img .*?>/g, '')" />
            <span class="text-sm font-inter tracking-[0px] font-medium flex items-center gap-1 leading-[16px] text-[#027DFF] hover:opacity-75">
              <span>Learn more</span>
              <Icon name="chevron-right" size="12" />
            </span>

          </div>

        </a>
      </div>
    </template>
    <template #footer>
      <Btn
        type="secondary"
        class="w-full"
        size="small"
        target="_blank"
        href="https://ycode.com/updates"
      >
        View all updates
      </Btn>
    </template>
  </Modal>
</template>

<script>
import axios from 'axios'
import Btn from './Btn'
import Modal from './Modal'
import Icon from '../../components/ycode-builder/components/UI/Icon'

export default {
  components: {
    Icon,
    Btn,
    Modal,
  },

  props: {
    token: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    updatesSeen: {
      default: [],
    },
    limit: {
      type: Number,
      default: 2,
    },
  },

  data() {
    return {
      updates: [],
      showModal: false,
      loadingUpdates: false,
    }
  },

  mounted() {
    this.loadingUpdates = true

    fetch(`https://app.ycode.com/api/v1/collections/6613cc435d4c3/items?sort_by=ID&order_by=desc&limit=${this.limit}`, {
      headers: { Authorization: `Bearer ${this.token}` },
    })
      .then((resp) => resp.json())
      .then((json) => {
        this.updates = json.data
        this.updates.forEach((update) => {
          if (!this.updatesSeen.includes(update._ycode_id)) this.showModal = true
        })
      })
  },

  methods: {
    modalClosed() {
      const ids = []
      this.updates.forEach((update) => ids.push(update._ycode_id))

      axios.post('/internal_api/users/updates', ids)
    },
  },

}
</script>
