<template>
  <div>
    <div
      v-if="type === 'default'"
      :class="size === 'default' ? 'lg:mb-8 mb-4' : 'lg:mb-6 mb-4'"
    >
      <div
        class="flex md:flex-row flex-col md:items-center justify-between space-y-2 md:space-y-0"
      >
        <div>
          <span v-if="subtitle" class="text-sm text-black text-opacity-50 tracking-wide">{{ subtitle }}</span>
          <h1
            v-if="size === 'default'"
            class="lg:text-3xl text-2xl leading-10 tracking-tight"
          >
            {{ title }}
          </h1>

          <h2
            v-else-if="size === 'small'"
            class="lg:text-2xl text-xl  tracking-tight"
          >
            {{ title }}
          </h2>
          <slot name="subtitle-actions" />
        </div>
        <div>
          <slot name="actions" />
        </div>
      </div>
      <div v-if="$slots.tabs" class="mt-2 flex space-x-2.5 -mx-3">
        <slot name="tabs" />
      </div>
    </div>

    <nav v-if="type === 'full'" class="fixed top-0 w-full flex items-center lg:p-8 px-8 py-6 bg-white lg:bg-transparent bg-opacity-80 z-40 backdrop-filter lg:backdrop-filter-none backdrop-blur-lg lg:backdrop-blur-none">
      <div class="w-1/3 flex">
        <slot name="actions" />
      </div>
      <div class="w-1/3 flex justify-center">
        <!--<img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlNpZGViYXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zMC4wMDAwMDAsIC0zMC4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9Illjb2RlIj4KICAgICAgICAgICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMwLjAwMDAwMCwgMzAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48L3JlY3Q+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTExLjQyNDE1MzMsMCBMMTEuNDI0MTUzMyw1Ljg1ODc3OTUxIEw2LjAyNCw4Ljk3OCBMMTIuNjE1NTczNSwxMi43ODY4MDA4IEwxMC45NTEsMTMuNzQ5IEwyMy4wNDY1NDAxLDYuNzUxMDEzNDkgTDIzLjA0NjU0MDEsMTIuNjE1MjcxNyBMMy4zOTUxNjA5NiwyMy45ODU2NjY2IEwzLjM3MDM3MjYsMjQgTDMuMzQzMTgxMjksMjMuOTgyNzE1NiBMMC45NiwyMi40NzEzMzY1IEwwLjk2LDE2Ljc2MTY1MDggTDMuMzY0MTc1NTEsMTguMTM5MzI0MiBMNy40NzYsMTUuNzYgTDAuOTYsMTEuOTA5MDA5OSBMMC45Niw2LjA1Mzc1NTE2IEwxMS40MjQxNTMzLDAgWiIgaWQ9Inktc3Ryb2tlIiBmaWxsPSIjMDAwMDAwIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=" width="28" class="">-->
      </div>
      <div class="w-1/3" />
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
    },
    subtitle: {
      default: '',
    },
    size: {
      default: 'default',
    },
    type: {
      default: 'default',
    },

  },
  data() {
    return {
      sizing: {
        default: { spacing: 'text-4xl' },
        small: { spacing: 'text-2xl' },
      },
    }
  },
}
</script>

<style scoped>

</style>
