<template>
  <div class="w-full bg-white -space-y-px mt-3">
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
