<template>
  <div
    class="relative flex transition duration-150 ease-in-out"
    :class="[
      isChecked ? 'bg-y-blue-500 bg-opacity-10 border-y-blue-500 border-opacity-50 z-10 ' : 'border-gray-200',
      borderless ? '!bg-transparent' : 'border first:rounded-t-lg last:rounded-b-lg  px-4'
    ]"
  >
    <div
      class="flex items-center"
      :class="[
        borderless ? '' : 'mt-4 h-5'
      ]"
    >
      <input
        :id="id"
        :name="name"
        :value="value"
        type="checkbox"
        :checked="isChecked"
        class="h-4 w-4 text-y-blue-500 focus:ring-0 focus:ring-opacity-0 border-gray-400 rounded"
        @change="markAsChecked($event)"
      >
    </div>

    <label
      :for="id"
      class=" flex flex-col cursor-pointer w-full"
      :class="[
        borderless ? 'ml-1.5' : 'ml-3 py-4'
      ]"
    >
      <span class="block text-sm leading-5 tracking-wide text-black">
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      default: '',
    },
    name: {
      default: '',
    },
    value: {
      default: '',
    },
    label: {
      default: '',
    },
    checked: {
      default: false,
    },
    borderless: {
      default: false,
    },
  },

  data() {
    return {
      checkedCopy: this.checked || false,
    }
  },

  computed: {
    isChecked() {
      return this.checkedCopy
    },
  },

  methods: {
    markAsChecked(event) {
      this.$nextTick(() => {
        this.checkedCopy = event.target.checked
      })
    },
  },
}
</script>

<style scoped>

</style>
