<template>
  <div :class="[types[type].style]" class="rounded-lg">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: 'default',
    },
  },
  data() {
    return {
      types: {
        default: { style: 'border border-black border-opacity-5 p-10' },
        light: { style: 'bg-black bg-opacity-5 p-10' },
        ghost: { style: '' },
      },
    }
  },
}
</script>
